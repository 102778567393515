const linksToAppStoreByClient: Record<string, any> = {
  msupermarket: {
    prod: 'itms-apps://itunes.apple.com/app/id6483924050',
    dev: 'itms-apps://itunes.apple.com/app/id6478955427',
  },
  baraka: { prod: 'itms-apps://itunes.apple.com/app/id6472742783' },
}

const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

const getDataStringifiedFromLocationSearch = () => {
  const data: Record<string, any> = {}
  if (window.location.search) {
    const search = window.location.search.substr(1)
    const params = search.split('&')

    params.forEach(function (param) {
      const keyValue = param.split('=')
      const key = keyValue[0]
      const value = keyValue[1]
      data[key] = value
    })
  }

  return JSON.stringify({ data })
}

var textToPutOnClipboard = getDataStringifiedFromLocationSearch()

window.onload = () => {
  document
    .querySelector('.button-nav-to-appstore')
    .addEventListener('click', () => {
      copyToClipboard(textToPutOnClipboard)

      // window.location.hostname // baraka.test.smartkit.app // msupermarket.smartkit.r-proc.net
      const hostname = window.location.hostname
      const clientSlugMatchRes = hostname.match(
        /(?:(.*)\.smartkit\.(?:test\.)?r-proc\.net)|(?:(.*?)\.(?:test\.)?smartkit\.app)/,
      )
      if (
        clientSlugMatchRes &&
        (clientSlugMatchRes[1] || clientSlugMatchRes[2])
      ) {
        const clientSlug = clientSlugMatchRes[1] || clientSlugMatchRes[2]
        const appStoreLink = linksToAppStoreByClient[clientSlug]?.prod
        if (appStoreLink) {
          window.location.href = appStoreLink
        }
      }
    })
}
